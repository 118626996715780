import React from "react";
import { RecoilRoot } from "recoil";
import DefineTypeCommand from "./component/DefineTypeCommand.js";
import { SceneEditor } from "./component/SceneEditor";

function App() {
  return (
    <RecoilRoot>
      <div class="w-full h-full flex flex-row">
        <div class="w-2/6 h-full overflow-y-auto p-2 bg-gray-200 p-2 border-r border-black">
          <DefineTypeCommand />
        </div>
        <div class="w-4/6 h-full overflow-y-auto">
          <SceneEditor />
        </div>
      </div>
    </RecoilRoot>
  );
}

export default App;
