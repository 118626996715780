import { Button, Fab, Box, AppBar, Toolbar, IconButton } from "@mui/material";
import React, { useRef, useState } from "react";
import { useCharacters } from "../hook/useCharacters";
import { useImages } from "../hook/useImages";
import { useScenes } from "../hook/useScenes";
import {
  convertCharactersToScript,
  convertImagesToScript,
  convertScriptToTask,
  convertTasksToScript,
  downloadScriptFile,
} from "../utils/TaskConverter";
import { AddTaskDialog } from "./dialog/AddTaskDialog";
import { GuideStudioDeployDialog } from "./dialog/GuideStudioDeployDialog";
import { PreviewDialog } from "./dialog/PreviewDialog";
import { SceneList } from "./SceneList";
import { TaskList } from "./TaskList";

export function SceneEditor() {
  const navItems = [
    {
      name: "미리보기",
      onClick: () => {
        setOpenPreviewDialog(true);
      },
    },
    {
      name: "웹뷰 HTML",
      onClick: () => {
        setOpenGuideStudioDeployDialog(true);
      },
    },
    {
      name: "스크립트 불러오기",
      onClick: () => {
        inputFile.current.click();
      },
    },
    {
      name: "스크립트 다운로드",
      onClick: () => {
        const script = createScript();
        downloadScriptFile(script);
      },
    },
  ];

  const { scenes, addScene, addTask, updateTask, deleteTask, setScenes } =
    useScenes();
  const [selectedScene, setSelectedScene] = useState(null);
  const { characters, setCharacters } = useCharacters();
  const { images, setImages } = useImages();
  const [editingTask, setEditingTask] = useState(null);

  const inputFile = useRef(null);

  const [opendAddTaskDialog, setOpendAddTaskDialog] = useState(false);

  const [openPreviewDialog, setOpenPreviewDialog] = useState(false);

  const [openGuideStudioDeployDialog, setOpenGuideStudioDeployDialog] =
    useState(false);

  const closeAddTaskDialog = () => {
    setEditingTask(null);
    setOpendAddTaskDialog(false);
  };

  const closePreviewDialog = () => {
    setOpenPreviewDialog(false);
  };

  const closeGuideStudioDeployDialog = () => {
    setOpenGuideStudioDeployDialog(false);
  };

  const handleAddTask = (task) => {
    if (editingTask) {
      updateTask(selectedScene.id, task);
    } else {
      addTask(selectedScene.id, task);
    }
    closeAddTaskDialog();
  };

  const handleDeleteTask = (id) => {
    deleteTask(selectedScene.id, id);
    closeAddTaskDialog();
  };

  const handleEditTask = (task) => {
    setEditingTask(task);
    setOpendAddTaskDialog(true);
  };

  const handleScriptLoaded = (e) => {
    e.stopPropagation();
    e.preventDefault();
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.onloadend = () => {
      const { characters, images, scenes } = convertScriptToTask(reader.result);
      setCharacters(characters);
      setImages(images);
      setScenes(scenes);
    };
    reader.readAsText(file);
  };

  const createScript = () => {
    let script = "";
    script += convertCharactersToScript(characters);
    script += convertImagesToScript(images);

    script += "\n";

    scenes.forEach((scene) => {
      script += convertTasksToScript(scene.tasks);
      script += "\n";
    });

    return script;
  };

  return (
    <div>
      <AppBar position="inherit" component="nav">
        <Toolbar
          sx={{
            background: "white",
          }}
        >
          <div class="w-full flex">
            <Button
              style={{ whiteSpace: "nowrap" }}
              variant="outlined"
              onClick={addScene}
            >
              장면 추가
            </Button>
            <Box
              container
              justifyContent="flex-end"
              sx={{
                width: 1,
                display: "flex",
              }}
            >
              {navItems.map((item) => (
                <Button
                  key={item.name}
                  sx={{ color: "#000000" }}
                  onClick={item.onClick}
                >
                  {item.name}
                </Button>
              ))}
            </Box>
            <div class="h-full">
              <input
                type="file"
                id="file"
                ref={inputFile}
                onChange={handleScriptLoaded}
                class="hidden"
              />
            </div>
          </div>
        </Toolbar>
      </AppBar>
      <div class="p-2 overscroll-y-auto">
        <SceneList
          selectedScene={selectedScene}
          selectScene={(scene) => setSelectedScene(scene)}
          scenes={scenes}
        />
        {selectedScene ? (
          <TaskList
            selectedScene={selectedScene}
            editTask={handleEditTask}
            addTask={() => setOpendAddTaskDialog(true)}
          />
        ) : null}
        <AddTaskDialog
          taskToEdit={editingTask}
          addTask={handleAddTask}
          deleteTask={handleDeleteTask}
          objects={[...characters, ...images]}
          close={closeAddTaskDialog}
          openState={opendAddTaskDialog}
          key={new Date().getTime()}
        />
        <PreviewDialog
          close={closePreviewDialog}
          openState={openPreviewDialog}
          key={new Date().getTime() / 2}
        />
        <GuideStudioDeployDialog
          close={closeGuideStudioDeployDialog}
          openState={openGuideStudioDeployDialog}
          key={new Date().getTime() / 3}
        />
      </div>
    </div>
  );
}
