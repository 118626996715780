import React, { useState } from "react";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
} from "@mui/material";
import TaskTypes from "../../utils/TaskTypes";

export function AddTaskDialog({
  taskToEdit,
  addTask,
  deleteTask,
  close,
  openState,
  objects = [],
}) {
  const [selectedObject, setSelectedObject] = useState(
    taskToEdit ? taskToEdit.params[0] : objects[0] ? objects[0].name : null
  );

  const [editingCommand, setEditingCommand] = useState(
    taskToEdit
      ? {
          id: taskToEdit.id,
          type: taskToEdit.type,
          params: (function dropFirst() {
            const newParams = [...taskToEdit.params];
            newParams.shift(1);
            return newParams;
          })(),
        }
      : {
          type: TaskTypes[0],
          params: new Array(TaskTypes[0].params.length),
        }
  );

  const handleObjectSelect = (e) => {
    const name = e.target.value;
    setSelectedObject(name);
  };

  const handleParamInputsChange = (e, index) => {
    const params = [...editingCommand.params];
    params[index] = e.target.value;
    setEditingCommand({ type: editingCommand.type, params: params });
  };

  const handleCommandMenuSelect = (e) => {
    const commandType = e.target.value;
    const params = Array(commandType.params.length);
    params.fill("");
    setEditingCommand({ type: commandType, params: params });
  };

  const handleSubmit = () => {
    const params = [selectedObject, ...editingCommand.params];
    const type = editingCommand.type;
    addTask({
      id: taskToEdit ? taskToEdit.id : new Date().getTime(),
      type: type,
      params: params,
    });
  };

  const handleDelete = () => {
    deleteTask(taskToEdit.id);
  };

  const objectMenu = objects.map((object) => {
    return (
      <MenuItem key={object.name} value={object.name}>
        {object.name}
      </MenuItem>
    );
  });

  const taskMenu = TaskTypes.map((commandType) => {
    return (
      <MenuItem key={commandType.name} value={commandType}>
        {commandType.displayName}
      </MenuItem>
    );
  });

  const taskParamInputs = editingCommand.type.params.map((param, index) => {
    return (
      <div key={index}>
        <InputLabel sx={{ mt: 2 }}>{param.displayName}</InputLabel>
        <TextField
          sx={{ width: "100%" }}
          variant="outlined"
          placeholder={param.inputPlaceholder}
          value={editingCommand.params[index]}
          onChange={(e) => handleParamInputsChange(e, index)}
        />
      </div>
    );
  });

  return (
    <Dialog fullWidth={true} maxWidth="md" onClose={close} open={openState}>
      <DialogTitle onClose={close}>행동 추가</DialogTitle>
      <DialogContent>
        <FormControl sx={{ mt: 2 }} fullWidth>
          <InputLabel id="demo-simple-select-label">행동</InputLabel>
          <Select
            value={editingCommand.type}
            label="선택"
            onChange={handleCommandMenuSelect}
          >
            {taskMenu}
          </Select>
        </FormControl>
        <FormControl sx={{ mt: 2 }} fullWidth>
          <InputLabel id="input-character-or-image">
            행동을 수행할 캐릭터/이미지
          </InputLabel>
          <Select
            value={selectedObject}
            label="선택"
            onChange={handleObjectSelect}
          >
            {objectMenu}
          </Select>
        </FormControl>
        {taskParamInputs}
        <Button
          sx={{ mt: 4, width: "100%" }}
          variant="contained"
          onClick={handleSubmit}
        >
          {taskToEdit ? "수정" : "추가"}
        </Button>
        {taskToEdit ? (
          <Button
            sx={{ mt: 2, width: "100%" }}
            variant="outlined"
            color="error"
            onClick={handleDelete}
          >
            삭제
          </Button>
        ) : null}
      </DialogContent>
    </Dialog>
  );
}
