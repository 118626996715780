import { Button } from "@mui/material";
import React from "react";
import { useCharacters } from "../hook/useCharacters";

export function CharacterList({ editChracter, addCharacter }) {
  const { characters } = useCharacters();

  return (
    <div class="w-full pb-20">
      {characters.map((character) => {
        return (
          <div
            key={character.name}
            class="w-10/12 p-4 hover:bg-indigo-300	hover:rounded-2xl flex"
            onClick={() => editChracter(character)}
          >
            {`${character.name} `}{" "}
            <p class={`text-[${character.color}]`}>({character.displayName})</p>
          </div>
        );
      })}
      <Button
        sx={{ mt: 2 }}
        variant="outlined"
        fullWidth={true}
        onClick={addCharacter}
      >
        캐릭터 추가
      </Button>
    </div>
  );
}
