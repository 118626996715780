const TaskTypes = [
  {
    name: "MOVE",
    displayName: "좌표 이동",
    type: "Action",
    params: [
      {
        name: "pos_x",
        displayName: "X 좌표 (%로 입력 가능)",
      },
      {
        name: "pos_y",
        displayName: "Y 좌표 (%로 입력 가능)",
      },
      {
        name: "time",
        displayName: "이동 시간 (초)",
      },
    ],
    print: (task) => {
      const template = "{0}이(가) 좌표 ({1}, {2})로 {3}초 동안 이동합니다.";
      return template.format(task.params);
    },
  },
  {
    name: "SHAKE",
    displayName: "흔들기",
    type: "Action",
    params: [
      {
        name: "distance",
        displayName: "좌우 범위",
      },
      {
        name: "speed",
        displayName: "속도",
      },
      {
        name: "duration",
        displayName: "횟수",
      },
    ],
    print: (task) => {
      const template =
        "{0}이(가) {1}의 범위를 {2}의 속도로 {3}초 동안 좌우로 움직입니다.";
      return template.format(task.params);
    },
  },
  {
    name: "BOUNCE",
    displayName: "바운스",
    type: "Action",
    params: [
      {
        name: "height",
        displayName: "y축 높이 ('-'일 시 위로, '+'일 시 아래로)",
        inputPlaceholder: "Ex) -35",
      },
      {
        name: "speed",
        displayName: "속도",
      },
      {
        name: "duration",
        displayName: "횟수",
      },
    ],
    print: (task) => {
      const template =
        "{0}이(가) {1}의 높이를 {2}의 속도로 {3}번 상하로 움직입니다.";
      return template.format(task.params);
    },
  },
  {
    name: "PRINT",
    displayName: "대사 출력",
    type: "Action",
    params: [
      {
        name: "text",
        displayName: "출력할 텍스트",
      },
    ],
    print: (task) => {
      const template = "{0}이(가) '{1}' 대사를 출력합니다.";
      return template.format(task.params);
    },
    createScript: (task) => {
      return `PRINT (${task.params[0]}, "${task.params[1]}")`;
    },
    createParams: (params) => {
      const newParams = [...params];
      newParams[1] = newParams[1].substring(1);
      newParams[1] = newParams[1].slice(0, -1);
      return newParams;
    },
  },
  {
    name: "FADE",
    displayName: "페이드인/아웃",
    params: [
      {
        name: "start_opt",
        displayName: "시작 투명도 (0 ~ 1, null을 입력하면 현재 투명도 사용)",
      },
      {
        name: "end_opt",
        displayName: "끝 투명도 (0 ~ 1)",
      },
      {
        name: "time",
        displayName: "시간 (초)",
      },
    ],
    print: (task) => {
      const template =
        "{0}이(가) {1}에서 {2}까지 {3}초 동안 페이드인/아웃 합니다.";
      return template.format(task.params);
    },
  },
  {
    name: "SCALE",
    displayName: "스케일 조정",
    type: "Action",
    params: [
      {
        name: "scale_x",
        displayName: "가로 스케일 (음수를 입력하면 가로 반전)",
      },
      {
        name: "scale_y",
        displayName: "세로 스케일 (음수를 입력하면 세로 반전)",
      },
    ],
    print: (task) => {
      const template = "{0}이(가) ({1}, {2})만큼 스케일업 합니다.";
      return template.format(task.params);
    },
  },
  {
    name: "SHOW",
    displayName: "오브젝트 표시",
    type: "Action",
    params: [
      {
        name: "imageName",
        displayName: "이미지 이름",
      },
      {
        name: "pos_x",
        displayName:
          "표시할 X좌표 (%로 입력 가능, null을 입력하여 기본 좌표 사용)",
      },
      {
        name: "pos_y",
        displayName:
          "표시할 Y좌표 (%로 입력 가능, null을 입력하여 기본 좌표 사용)",
      },
      {
        name: "pos_z",
        displayName:
          "표시할 Z 높이 (기본값 : 1, null을 입력하여 현재 Z 높이 유지)",
      },
    ],
    print: (task) => {
      const template =
        "{0}이(가) {1} 이미지로 좌표 ({2}, {3}, {4})에 표시됩니다.";
      return template.format(task.params);
    },
  },
  {
    name: "SET_POS",
    displayName: "포지션 재설정",
    type: "Action",
    params: [
      {
        name: "pos_x",
        displayName: "X좌표 (null을 입력하여 기본 좌표 사용)",
      },
      {
        name: "pos_y",
        displayName: "Y좌표 (null을 입력하여 기본 좌표 사용)",
      },
      {
        name: "pos_z",
        displayName: "Z 레이어 (null을 입력하여 기본 좌표 사용)",
      },
    ],
    print: (task) => {
      const template = "{0}이(가) 좌표 ({1}, {2}, {3})으로 이동합니다.";
      return template.format(task.params);
    },
  },
  {
    name: "DISPOSE",
    displayName: "오브젝트 제거",
    type: "Action",
    params: [],
    print: (task) => {
      const template = "{0}이(가) 제거됩니다.";
      return template.format(task.params);
    },
  },
  {
    name: "BG",
    displayName: "배경 표시",
    type: "Action",
    params: [],
    print: (task) => {
      const template = "{0} 이미지가 배경으로 표시됩니다.";
      return template.format(task.params);
    },
  },
  {
    name: "RW_SUBMIT",
    displayName: "'특정 텍스트 입력' 리액션 실행",
    type: "Action",
    params: [
      {
        name: "value",
        displayName: "입력 값",
      },
    ],
    print: (task) => {
      const template = "'{1}'을(를) 퀘스트에 입력합니다.";
      return template.format(task.params);
    },
  },
  {
    name: "RW_CLOSE",
    type: "Action",
    displayName: "종료",
    params: [],
    print: (task) => {
      const template = "웹뷰를 종료합니다.";
      return template.format(task.params);
    },
  },
];

export default TaskTypes;

String.prototype.format = function () {
  var args = arguments;
  return this.replace(/{([0-9]+)}/g, function (match, index) {
    // check if the argument is there
    return typeof args[0][index] == "undefined" ? "null" : args[0][index];
  });
};
