import { Dialog, DialogContent, DialogTitle } from "@mui/material";
import React from "react";

export function PreviewDialog({ close, openState }) {
  const iframePart = () => {
    return {
      __html: `<iframe src="preview.html" width="100%" height="100%"></iframe>`,
    };
  };

  return (
    <Dialog fullWidth={true} maxWidth="md" onClose={close} open={openState}>
      <DialogTitle onClose={close}>미리보기</DialogTitle>
      <DialogContent style={{ height: "600px" }}>
        <div
          style={{
            margin: "auto",
            position: "relative",
            width: "100%",
            height: "100%",
            overflow: "hidden",
          }}
          dangerouslySetInnerHTML={iframePart()}
        />
      </DialogContent>
    </Dialog>
  );
}
