import {
  InputLabel,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";
import { useState } from "react";
import React from "react";
import { useImages } from "../../hook/useImages";

export function AddImageDialog({ imageToEdit, close, openState }) {
  const [imageName, setImageName] = useState(
    imageToEdit ? imageToEdit.name : ""
  );
  const [imageSourceUrl, setImageSourceUrl] = useState(
    imageToEdit ? imageToEdit.url : ""
  );

  const { addImage, deleteImage } = useImages();

  const handleSubmit = () => {
    console.log("submit");
    if (imageName == "" || imageSourceUrl == "") {
      return;
    }
    const newImage = {
      name: imageName,
      url: imageSourceUrl,
    };
    addImage(newImage);
    close();
  };

  const handleDelete = () => {
    deleteImage(imageToEdit.name);
    close();
  };

  return (
    <Dialog
      sx={{
        "& .MuiDialog-container": {
          "& .MuiPaper-root": {
            width: "100%",
            maxWidth: "80%",
          },
        },
      }}
      maxWidth="md"
      onClose={close}
      open={openState}
    >
      <DialogTitle onClose={close}>이미지 추가</DialogTitle>
      <DialogContent sx={{ display: "flex" }}>
        <div class="w-2/4">
          <InputLabel sx={{ mt: 2 }}>이미지 이름</InputLabel>
          <TextField
            sx={{ width: "100%" }}
            variant="outlined"
            value={imageName}
            onChange={(e) => setImageName(e.target.value)}
          />
          <InputLabel sx={{ mt: 2 }}>이미지 주소 (URL)</InputLabel>
          <TextField
            sx={{ width: "100%" }}
            variant="outlined"
            value={imageSourceUrl}
            onChange={(e) => setImageSourceUrl(e.target.value)}
          />

          <Button
            sx={{ mt: 4, width: "100%" }}
            variant="contained"
            onClick={handleSubmit}
          >
            {imageToEdit ? "수정" : "추가"}
          </Button>
          {imageToEdit ? (
            <Button
              sx={{ mt: 2, width: "100%" }}
              variant="outlined"
              color="error"
              onClick={handleDelete}
            >
              삭제
            </Button>
          ) : null}
        </div>
        <img class="w-2/4 p-8" src={imageSourceUrl} alt="미리보기" />
      </DialogContent>
    </Dialog>
  );
}
