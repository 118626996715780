import React from "react";
import { useState } from "react";
import { Box, Tabs, Tab, Fab } from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { CharacterList } from "./CharacterList";
import { AddCharacterDialog } from "./dialog/AddCharacterDialog";
import { AddImageDialog } from "./dialog/AddImageDialog";
import { ImageList } from "./ImageList";

function DefineTypeCommand() {
  const [tabIndex, setTabIndex] = useState("0");

  const [openAddCharacterDialog, setOpenAddCharacterDialog] = useState(false);

  const [openAddImageDialog, setOpenAddImageDialog] = useState(false);

  const [editingChracter, setEditingChracter] = useState(null);

  const [editingImage, setEditingImage] = useState(null);

  const closeAddCharacterDialog = () => {
    setEditingChracter(null);
    setOpenAddCharacterDialog(false);
  };

  const closeAddImageDialog = () => {
    setEditingImage(null);
    setOpenAddImageDialog(false);
  };

  const editChracter = (chracter) => {
    setEditingChracter(chracter);
    setOpenAddCharacterDialog(true);
  };

  const editImage = (image) => {
    setEditingImage(image);
    setOpenAddImageDialog(true);
  };

  return (
    <div>
      <TabContext value={tabIndex}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <TabList
            onChange={(e, newValue) => setTabIndex(newValue)}
            aria-label="basic tabs example"
          >
            <Tab label="캐릭터" value="0" />
            <Tab label="이미지" value="1" />
          </TabList>
        </Box>
        <TabPanel value="0">
          <CharacterList
            editChracter={editChracter}
            addCharacter={() => setOpenAddCharacterDialog(true)}
          />
        </TabPanel>
        <TabPanel value="1">
          <ImageList
            editImage={editImage}
            addImage={() => setOpenAddImageDialog(true)}
          />
        </TabPanel>
      </TabContext>
      <AddCharacterDialog
        characterToEdit={editingChracter}
        close={closeAddCharacterDialog}
        openState={openAddCharacterDialog}
        key={new Date().getTime() / 2}
      />
      <AddImageDialog
        imageToEdit={editingImage}
        close={closeAddImageDialog}
        openState={openAddImageDialog}
        key={new Date().getTime() / 3}
      />
    </div>
  );
}

export default DefineTypeCommand;
