import {
  InputLabel,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import React, { useRef } from "react";

const html = `<head>
<meta
  name="viewport"
  content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no"/>
<link rel="stylesheet" type="text/css" href="https://realworld.blob.core.windows.net/project-files/m9mu5sWh2k38Iud65PHgkg/_2RDGoPevcIu7RVfyzIEMw-style.css" />
</head>
<body>
<div id="container">
  <canvas id="canvas"> </canvas>
</div>
<div id="system-overlay">
  <div id="execute-area"></div>
  <div id="btn-close"></div>
</div>
<script src="https://realworld.blob.core.windows.net/project-files/m9mu5sWh2k38Iud65PHgkg/-nOYINoIYJ5mGstEnr0x1g-kowork.js"></script>
<script src="https://realworld.blob.core.windows.net/project-files/ugUrxYTmUERV4_4EJjdUBw/Xk0ly8JxNLtj1Jx45jVcnw-rw_extends.js"></script>
<script type="text/javascript">
  option.FPS = 60;
  option.width = 720;
  option.height = 1280;
  textBoxSetting.lineHeight = 22;
  textBoxSetting.margin.bottom = 20;
  option.nameSettings.font.name = "Black Han Sans";
  option.nameSettings.font.url =
    "https://realworld.blob.core.windows.net/project-files/ugUrxYTmUERV4_4EJjdUBw/JLrAdYxLmujplBJFohLGTA-MochiyPopOne_Regular.ttf";
  option.nameSettings.size = 32;
  option.dialogueSettings.font.name = "Nanum Gothic";
  option.dialogueSettings.font.url =
    "https://realworld.blob.core.windows.net/project-files/ugUrxYTmUERV4_4EJjdUBw/EhIErf9VHvmXynQjchNAbw-NanumBarunGothic.ttf";
  option.dialogueSettings.color = "white";
  option.dialogueSettings.size = 28;
  play("이곳을 스크립트 URL로 변경해주세요.");
</script>
</body>
`;

export function GuideStudioDeployDialog({ close, openState }) {
  return (
    <Dialog
      sx={{
        "& .MuiDialog-container": {
          "& .MuiPaper-root": {
            width: "100%",
            maxWidth: "80%",
          },
        },
      }}
      maxWidth="md"
      onClose={close}
      open={openState}
    >
      <DialogTitle onClose={close}>
        {`아래 HTML을 복사 후, 아래쪽의 play("이곳을 스크립트...") 부분을 변경하여 사용해주세요.`}
      </DialogTitle>
      <DialogContent>
        <div class="bg-gray-100 p-8 whitespace-pre-line">{html}</div>
      </DialogContent>
    </Dialog>
  );
}
