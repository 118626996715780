import React from "react";
import { useRecoilState } from "recoil";
import { scenesState } from "../states/scenesState";

export function useScenes() {
  const [scenes, setScenes] = useRecoilState(scenesState);

  const addScene = () => {
    setScenes([
      ...scenes,
      {
        id: scenes.length,
        tasks: [],
      },
    ]);
  };

  const addTask = (id, task) => {
    const prev = [...scenes];
    const updatedScenes = prev.map((scene) => {
      if (scene.id !== id) {
        return scene;
      } else {
        const newScene = {
          id: scene.id,
          tasks: [...scene.tasks, task],
        };
        return newScene;
      }
    });
    setScenes(updatedScenes);
  };

  const updateTask = (id, taskToUpdate) => {
    const prev = [...scenes];
    const updatedScenes = prev.map((scene) => {
      if (scene.id !== id) {
        return scene;
      } else {
        const newTasks = scene.tasks.map((task) => {
          return task.id === taskToUpdate.id ? taskToUpdate : task;
        });
        const newScene = {
          id: scene.id,
          tasks: newTasks,
        };
        return newScene;
      }
    });
    setScenes(updatedScenes);
  };

  const deleteTask = (id, taskIdToDelete) => {
    const prev = [...scenes];
    const updatedScenes = prev.map((scene) => {
      if (scene.id !== id) {
        return scene;
      } else {
        const newTasks = scene.tasks.filter((task) => {
          return task.id !== taskIdToDelete;
        });
        const newScene = {
          id: scene.id,
          tasks: newTasks,
        };
        return newScene;
      }
    });
    setScenes(updatedScenes);
  };

  const remove = (name) => {};

  return {
    scenes,
    addTask,
    addScene,
    deleteTask,
    remove,
    updateTask,
    setScenes,
  };
}
