import React from "react";
import { useRecoilState } from "recoil";
import { charactersState } from "../states/charactersState";

export function useCharacters() {
  const [characters, setCharacters] = useRecoilState(charactersState);

  const addCharacter = (character) => {
    if (characters.find((c) => c.name === character.name)) {
      updateCharacter(character);
    } else {
      setCharacters([...characters, character]);
    }
  };

  const updateCharacter = (characterToUpdate) => {
    const prev = [...characters];
    const updatedCharacters = prev.map((character) =>
      character.name !== characterToUpdate.name ? character : characterToUpdate
    );
    setCharacters(updatedCharacters);
  };

  const deleteCharacter = (name) => {
    const prev = [...characters];
    const updatedCharacters = prev.filter(
      (character) => character.name !== name
    );
    setCharacters(updatedCharacters);
  };

  return {
    characters,
    setCharacters,
    addCharacter,
    updateCharacter,
    deleteCharacter,
  };
}
