import { Button } from "@mui/material";
import React from "react";
import { useScenes } from "../hook/useScenes";

export function TaskList({ selectedScene, editTask, addTask }) {
  const { scenes } = useScenes();

  const tasks = scenes.find((scene) => scene.id === selectedScene.id).tasks;

  return (
    <div class="w-full p-2 overflow-y-auto">
      {tasks.map((task, index) => {
        return (
          <div
            key={index}
            class="w-10/12 p-2 mt-1 mb-1 hover:bg-indigo-300	hover:rounded-2xl"
            onClick={() => {
              editTask(task);
            }}
          >
            {task.type.print(task)}
          </div>
        );
      })}
      <Button
        sx={{ mt: 2 }}
        variant="outlined"
        fullWidth={true}
        onClick={addTask}
      >
        행동 추가
      </Button>
    </div>
  );
}
