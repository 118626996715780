import TaskTypes from "./TaskTypes";

export function convertScriptToTask(scripts) {
  const characters = [];
  const images = [];
  const scenes = [
    {
      id: 0,
      tasks: [],
    },
  ];

  const scriptList = scripts.split(/\r?\n/);
  scriptList.forEach((script, index) => {
    if (!script) {
      if (scenes[scenes.length - 1].tasks.length > 0)
        scenes.push({
          id: scenes.length,
          tasks: [],
        });
      return;
    }
    let [command, task] = script.split(/ (.*)/s);

    if (command === "CHAR") {
      task = task.replace(/ /g, "");
      task = task.substring(1);
      task = task.slice(0, -1);
      const values = task.split(",");
      characters.push({
        name: values[0],
        displayName: values[1],
        color: values[2],
      });
    } else if (command === "IMG") {
      task = task.replace(/ /g, "");
      task = task.substring(1);
      task = task.slice(0, -1);
      const values = task.split(",");
      images.push({
        name: values[0],
        url: values[1],
      });
    } else {
      task = task.substring(1);
      task = task.slice(0, -1);
      const values = task.split(",").map((splited) => {
        return splited.trimLeft().trimRight();
      });
      console.log(values);
      const type = TaskTypes.filter((type) => type.name === command)[0];
      const newParams = type.hasOwnProperty("createParams")
        ? type.createParams(values)
        : values;
      const newTask = {
        id: index,
        type: type,
        params: newParams,
      };
      const scene = scenes[scenes.length - 1];
      scenes[scenes.length - 1] = {
        id: scene.id,
        tasks: [...scene.tasks, newTask],
      };
    }
  });
  return { characters, images, scenes };
}

export function convertCharactersToScript(characters) {
  let script = "";
  characters.forEach((character) => {
    script += `CHAR (${character.name}, ${character.displayName}, ${character.color})\n`;
  });
  return script;
}

export function convertImagesToScript(images) {
  let script = "";
  images.forEach((image) => {
    script += `IMG (${image.name}, ${image.url})\n`;
  });
  return script;
}

export function convertTasksToScript(tasks) {
  let script = "";
  tasks.forEach((task) => {
    if (task.type.hasOwnProperty("createScript")) {
      script += task.type.createScript(task);
      script += "\n";
    } else {
      script += `${task.type.name} (${task.params})\n`;
    }
  });
  return script;
}

export function downloadScriptFile(script) {
  const element = document.createElement("a");
  const file = new Blob([script], {
    type: "text/plain",
  });
  element.href = URL.createObjectURL(file);
  element.download = "script.txt";
  document.body.appendChild(element);
  element.click();
}
