import {
  InputLabel,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";
import { useState } from "react";
import React from "react";
import { useCharacters } from "../../hook/useCharacters";
import { HexColorPicker } from "react-colorful";

export function AddCharacterDialog({ characterToEdit, close, openState }) {
  const [characterName, setCharacterName] = useState(
    characterToEdit ? characterToEdit.name : ""
  );
  const [characterDisplayName, setCharacterDisplayName] = useState(
    characterToEdit ? characterToEdit.displayName : ""
  );

  const [color, setColor] = useState(
    characterToEdit ? characterToEdit.color : "#000000"
  );

  const { addCharacter, deleteCharacter } = useCharacters();

  const handleSubmit = () => {
    if (characterName == "" || color == "") {
      return;
    }
    const newCharacter = {
      name: characterName,
      displayName: characterDisplayName,
      color: color,
    };
    addCharacter(newCharacter);
    close();
  };

  const handleDelete = () => {
    deleteCharacter(characterToEdit.name);
    close();
  };

  return (
    <Dialog fullWidth={true} maxWidth="md" onClose={close} open={openState}>
      <DialogTitle onClose={close}>캐릭터 추가</DialogTitle>
      <DialogContent>
        <InputLabel sx={{ mt: 2 }}>이름</InputLabel>
        <TextField
          sx={{ width: "100%" }}
          variant="outlined"
          value={characterName}
          onChange={(e) => setCharacterName(e.target.value)}
        />
        <InputLabel sx={{ mt: 2 }}>
          {`표시 이름 (null을 입력하면 '${characterName}'을 사용하게 됩니다. 공백으로 입력하여
          이름을 숨길 수 있습니다.)`}
        </InputLabel>
        <TextField
          sx={{ width: "100%" }}
          variant="outlined"
          value={characterDisplayName}
          onChange={(e) => setCharacterDisplayName(e.target.value)}
        />
        <InputLabel sx={{ mt: 2 }}>이름 표시 색상</InputLabel>
        <div class="flex">
          <HexColorPicker color={color} onChange={setColor} />
          <TextField
            sx={{ width: "80%", m: 2 }}
            variant="outlined"
            value={color}
            onChange={(e) => setColor(e.target.value)}
          />
        </div>
        <Button
          sx={{ mt: 4, width: "100%" }}
          variant="contained"
          onClick={handleSubmit}
        >
          {characterToEdit ? "수정" : "추가"}
        </Button>
        {characterToEdit ? (
          <Button
            sx={{ mt: 2, width: "100%" }}
            variant="outlined"
            color="error"
            onClick={handleDelete}
          >
            삭제
          </Button>
        ) : null}
      </DialogContent>
    </Dialog>
  );
}
