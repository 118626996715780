import React from "react";
import { useRecoilState } from "recoil";
import { imagesState } from "../states/imagesState";

export function useImages() {
  const [images, setImages] = useRecoilState(imagesState);

  const addImage = (image) => {
    if (images.find((i) => i.name === image.name)) {
      updateImage(image);
    } else {
      setImages([...images, image]);
    }
  };

  const updateImage = (imageToUpdate) => {
    const prev = [...images];
    const updatedImages = prev.map((image) =>
      image.name !== imageToUpdate.name ? image : imageToUpdate
    );
    setImages(updatedImages);
  };

  const deleteImage = (name) => {
    const prev = [...images];
    const updatedImages = prev.filter((image) => image.name !== name);
    setImages(updatedImages);
  };

  return { images, addImage, updateImage, deleteImage, setImages };
}
