import { Button } from "@mui/material";
import React from "react";
import { useImages } from "../hook/useImages";

export function ImageList({ editImage, addImage }) {
  const { images } = useImages();

  return (
    <div class="w-full pb-20">
      {images.map((image) => {
        return (
          <div
            key={image.name}
            class="w-10/12 p-4 hover:bg-indigo-300	hover:rounded-2xl"
            onClick={() => editImage(image)}
          >
            {image.name}
          </div>
        );
      })}
      <Button
        sx={{ mt: 2 }}
        variant="outlined"
        fullWidth={true}
        onClick={addImage}
      >
        이미지 추가
      </Button>
    </div>
  );
}
