import React from "react";

export function SceneList({ selectedScene, selectScene, scenes = [] }) {
  return (
    <div class="w-full flex overflow-x-auto p-1">
      {scenes.map((scene) => {
        return (
          <div
            key={scene.id}
            class={`w-fit whitespace-nowrap px-8 py-2 hover:shadow-md ${
              scene.id === (selectedScene ? selectedScene.id : -1)
                ? "rounded border-solid border-2 border-blue-500	"
                : null
            }`}
            onClick={() => selectScene(scene)}
          >
            장면 {scene.id}
          </div>
        );
      })}
    </div>
  );
}
